import { HttpErrorResponse } from '@angular/common/http';
import { createReducer, on } from '@ngrx/store';
import { accountActions } from '../actions/account.actions';
import { ErrorsModel } from '../models';

export interface AccountState {
    saving: boolean | null;
    registered: boolean | null;
    logged: boolean | null;
    loggedOut: boolean | null;
    requestedResetPassword: boolean | null;
    resetPassword: boolean | null;
    activated: boolean | null;
    errors: ErrorsModel | null;
}

export const initialState: AccountState = {
    saving: null,
    registered: null,
    logged: null,
    loggedOut: null,
    requestedResetPassword: null,
    resetPassword: null,
    activated: null,
    errors: null,
};

export const accountReducer = createReducer(
    initialState,
    // register
    on(
        accountActions.register,
        (state): AccountState => ({
            ...state,
            saving: true,
            registered: false,
            errors: null,
        }),
    ),
    on(
        accountActions.registerSuccess,
        (state): AccountState => ({
            ...state,
            saving: false,
            registered: true,
        }),
    ),
    on(
        accountActions.registerError,
        (state, payload): AccountState => ({
            ...state,
            saving: false,
            registered: false,
            errors: payload.errors,
        }),
    ),
    // login
    on(
        accountActions.login,
        (state): AccountState => ({
            ...state,
            saving: true,
            logged: false,
            errors: null,
        }),
    ),
    on(
        accountActions.loginSuccess,
        (state): AccountState => ({
            ...state,
            saving: false,
            logged: true,
        }),
    ),
    on(
        accountActions.loginError,
        (state): AccountState => ({
            ...state,
            saving: false,
            logged: false,
        }),
    ),
    // logout
    on(
        accountActions.logout,
        (state): AccountState => ({
            ...state,
            saving: true,
            errors: null,
        }),
    ),
    on(
        accountActions.logoutSuccess,
        (state): AccountState => ({
            ...state,
            saving: false,
            loggedOut: true,
        }),
    ),
    on(
        accountActions.logoutError,
        (state): AccountState => ({
            ...state,
            saving: false,
            loggedOut: false,
        }),
    ),
    // request reset password
    on(
        accountActions.requestResetPassword,
        (state): AccountState => ({
            ...state,
            saving: true,
            requestedResetPassword: false,
            errors: null,
        }),
    ),
    on(
        accountActions.requestResetPasswordSuccess,
        (state): AccountState => ({
            ...state,
            saving: false,
            requestedResetPassword: true,
        }),
    ),
    on(
        accountActions.requestResetPasswordError,
        (state): AccountState => ({
            ...state,
            saving: false,
            requestedResetPassword: false,
        }),
    ),
    // reset password
    on(
        accountActions.resetPassword,
        (state): AccountState => ({
            ...state,
            saving: true,
            resetPassword: false,
            errors: null,
        }),
    ),
    on(
        accountActions.resetPasswordSuccess,
        (state): AccountState => ({
            ...state,
            saving: false,
            resetPassword: true,
            errors: null,
        }),
    ),
    on(accountActions.resetPasswordError, (state, action): AccountState => {
        if (!(<HttpErrorResponse>action.response)?.status) {
            return {
                ...state,
                saving: false,
                resetPassword: false,
                errors: (<{ data: ErrorsModel }>action.response).data,
            };
        }
        return {
            ...state,
            saving: false,
            resetPassword: false,
            errors: null,
        };
    }),
    // activate
    on(
        accountActions.activate,
        (state): AccountState => ({
            ...state,
            saving: true,
            activated: null,
            errors: null,
        }),
    ),
    on(
        accountActions.activateSuccess,
        (state): AccountState => ({
            ...state,
            saving: false,
            activated: true,
        }),
    ),
    on(
        accountActions.activateError,
        (state): AccountState => ({
            ...state,
            saving: false,
            activated: false,
        }),
    ),
    // clear
    on(
        accountActions.clear,
        (): AccountState => ({
            ...initialState,
        }),
    ),
);
