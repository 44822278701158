import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountState } from '../reducers/account.reducer';
import { ACCOUNT_FEATURE_KEY } from '../keys';

const selectState = createFeatureSelector<AccountState>(ACCOUNT_FEATURE_KEY);

export const selectRegistered = createSelector(selectState, (state: AccountState) => {
    return state.registered;
});

export const selectLogged = createSelector(selectState, (state: AccountState) => {
    return state.logged;
});
export const selectLoggedOut = createSelector(selectState, (state: AccountState) => {
    return state.loggedOut;
});

export const selectSaving = createSelector(selectState, (state: AccountState) => {
    return state.saving;
});

export const selectRequestedResetPassword = createSelector(selectState, (state: AccountState) => {
    return state.requestedResetPassword;
});

export const selectResetPassword = createSelector(selectState, (state: AccountState) => {
    return state.resetPassword;
});

export const selectActivated = createSelector(selectState, (state: AccountState) => {
    return state.activated;
});

export const selectErrors = createSelector(selectState, (state: AccountState) => {
    return state.errors;
});
