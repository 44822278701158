import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { AlertsActionsService, AlertsService } from '@app/shared/alerts';
import { LoaderFacade } from '@app/store/loader';
import { CleanupService } from '../../services/cleanup.service';
import { userActions } from '@app/store/user/actions/user.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, finalize, map, mergeMap, tap, of, filter } from 'rxjs';
import { accountActions } from '../actions/account.actions';
import { ErrorsModel } from '../models';
import { HttpService } from '../services/http.service';

@Injectable()
export class AccountEffects {
    private readonly actions = inject(Actions);
    private readonly httpService = inject(HttpService);
    private readonly loaderFacade = inject(LoaderFacade);
    private readonly alertsService = inject(AlertsService);
    private readonly alertsActionsService = inject(AlertsActionsService);
    private readonly cleanupService = inject(CleanupService);

    // register
    register$ = createEffect(() => {
        return this.actions.pipe(
            ofType(accountActions.register),
            mergeMap((action) => {
                this.loaderFacade.add('register');
                return this.httpService.register(action.value).pipe(
                    map(() => accountActions.registerSuccess()),
                    catchError((errors: ErrorsModel) => of(accountActions.registerError({ errors }))),
                    finalize(() => this.loaderFacade.remove('register')),
                );
            }),
        );
    });

    // login
    login$ = createEffect(() => {
        return this.actions.pipe(
            ofType(accountActions.login),
            mergeMap((action) => {
                this.loaderFacade.add('login');
                return this.httpService.login(action.value).pipe(
                    map(() => accountActions.loginSuccess()),
                    catchError((response: HttpErrorResponse) => of(accountActions.loginError({ response, email: action.value.email }))),
                );
            }),
        );
    });

    loginSuccessDispatch$ = createEffect(() => {
        return this.actions.pipe(
            ofType(accountActions.loginSuccess),
            map(() => userActions.get()),
        );
    });

    loginSuccess$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(accountActions.loginSuccess),
                tap(() => {
                    this.alertsService.show('login.message.success.log-in', 'success');
                    this.loaderFacade.remove('login');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    loginError$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(accountActions.loginError),
                tap((payload) => {
                    switch (payload.response.status) {
                        case 401:
                            this.alertsService.show('login.message.error.invalid-credentials', 'danger');
                            break;
                        case 403:
                            this.alertsService.show('login.message.error.not-activated', 'warning', true, 'window', {
                                action: 'resend-activation-link',
                                link: 'login.message.error.not-activated-link',
                                data: {
                                    email: payload.email,
                                },
                                hideOnAction: true,
                            });

                            break;
                    }
                    this.loaderFacade.remove('login');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    // login
    logout$ = createEffect(() => {
        return this.actions.pipe(
            ofType(accountActions.logout),
            mergeMap(() => {
                this.loaderFacade.add('logout');
                return this.httpService.logout().pipe(
                    map(() => accountActions.logoutSuccess()),
                    catchError(() => of(accountActions.logoutError())),
                );
            }),
        );
    });

    logoutSuccess$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(accountActions.logoutSuccess),
                tap(() => {
                    this.cleanupService.cleanup();

                    this.alertsService.show('logout.message.success.logged-out', 'success');
                    this.loaderFacade.remove('logout');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    logoutError$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(accountActions.logoutError),
                tap(() => {
                    this.alertsService.show('logout.message.error', 'danger');
                    this.loaderFacade.remove('logout');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    // request reset password
    requestResetPassword$ = createEffect(() => {
        return this.actions.pipe(
            ofType(accountActions.requestResetPassword),
            mergeMap((value) => {
                this.loaderFacade.add('request-reset-password');
                return this.httpService.requestResetPassword(value.email).pipe(
                    map(() => accountActions.requestResetPasswordSuccess()),
                    catchError((errors: ErrorsModel) => of(accountActions.requestResetPasswordError({ errors }))),
                    finalize(() => this.loaderFacade.remove('request-reset-password')),
                );
            }),
        );
    });

    // reset password
    resetPassword$ = createEffect(() => {
        return this.actions.pipe(
            ofType(accountActions.resetPassword),
            mergeMap((value) => {
                this.loaderFacade.add('reset-password');
                return this.httpService.resetPassword(value.password, value.token).pipe(
                    map(() => accountActions.resetPasswordSuccess()),
                    catchError((response: HttpErrorResponse) => of(accountActions.resetPasswordError({ response }))),
                );
            }),
        );
    });

    resetPasswordSuccess$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(accountActions.resetPasswordSuccess),
                tap(() => {
                    this.alertsService.show('reset-password.message.success', 'success');
                    this.loaderFacade.remove('reset-password');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    resetPasswordError$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(accountActions.resetPasswordError),
                tap((response) => {
                    if ((<HttpErrorResponse>response.response)?.status) {
                        this.alertsService.show('reset-password.message.error', 'danger');
                    }
                    this.loaderFacade.remove('reset-password');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    // activated
    activated$ = createEffect(() => {
        return this.actions.pipe(
            ofType(accountActions.activate),
            mergeMap((value) => {
                this.loaderFacade.add('activate');
                return this.httpService.activate(value.token).pipe(
                    map(() => accountActions.activateSuccess()),
                    catchError((errors: ErrorsModel) => of(accountActions.activateError({ errors }))),
                );
            }),
        );
    });

    activatedSuccess$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(accountActions.activateSuccess),
                tap(() => {
                    this.alertsService.show('account.activate.message.success', 'success');

                    this.loaderFacade.remove('activate');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    activatedError$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(accountActions.activateError),
                tap(() => {
                    this.alertsService.show('account.activate.message.error', 'danger');

                    this.loaderFacade.remove('activate');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    resendActivationLink$ = createEffect(() => {
        return this.alertsActionsService.bus$.pipe(
            filter((event) => event.action === 'resend-activation-link'),
            map((event) => event.data['email']),
            filter((email) => typeof email === 'string'),
            mergeMap((email) => {
                this.loaderFacade.add('resend-activation-link');
                return this.httpService.resendActivationLink(email).pipe(
                    map(() => accountActions.resendActivationLinkSuccess()),
                    catchError(() => of(accountActions.resendActivationLinkError())),
                );
            }),
        );
    });

    resendActivationLinkSuccess$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(accountActions.resendActivationLinkSuccess),
                tap(() => {
                    this.alertsService.show('account.resend-activation-link.message.success', 'success');
                    this.loaderFacade.remove('resend-activation-link');
                }),
            );
        },
        {
            dispatch: false,
        },
    );

    resendActivationLinkError$ = createEffect(
        () => {
            return this.actions.pipe(
                ofType(accountActions.resendActivationLinkError),
                tap(() => {
                    this.loaderFacade.remove('resend-activation-link');
                }),
            );
        },
        {
            dispatch: false,
        },
    );
}
