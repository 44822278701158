import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { ACCOUNT_FEATURE_KEY } from './keys';
import { accountReducer } from './reducers/account.reducer';
import { AccountEffects } from './effects/account.effects';

@NgModule({
    imports: [CommonModule, StoreModule.forFeature(ACCOUNT_FEATURE_KEY, accountReducer), EffectsModule.forFeature([AccountEffects])],
})
export class AccountStoreModule {}
