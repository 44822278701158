import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import {
    selectRegistered,
    selectLogged,
    selectSaving,
    selectErrors,
    selectRequestedResetPassword,
    selectResetPassword,
    selectActivated,
    selectLoggedOut,
} from '../selectors/account.selector';

import { accountActions } from '../actions/account.actions';
import { RegisterConsentModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class AccountFacade {
    private readonly store = inject(Store);

    readonly registered$ = this.store.select(selectRegistered).pipe(filter((data) => data !== null));
    readonly logged$ = this.store.select(selectLogged).pipe(filter((data) => data !== null));
    readonly loggedOut$ = this.store.select(selectLoggedOut).pipe(filter((data) => data !== null));
    readonly saving$ = this.store.select(selectSaving).pipe(filter((data) => data !== null));
    readonly requestedResetPassword$ = this.store.select(selectRequestedResetPassword).pipe(filter((data) => data !== null));
    readonly resetPassword$ = this.store.select(selectResetPassword).pipe(filter((data) => data !== null));
    readonly activated$ = this.store.select(selectActivated).pipe(filter((data) => data !== null));
    readonly errors$ = this.store.select(selectErrors).pipe(filter((data) => data !== null));

    register(email: string, password: string, assignBookingId: string | null, consent: RegisterConsentModel): void {
        this.store.dispatch(accountActions.register({ value: { email, password, assignBookingId, consent } }));
    }

    login(email: string, password: string, assignBookingId: string | null): void {
        this.store.dispatch(accountActions.login({ value: { email, password, assignBookingId } }));
    }

    logout(): void {
        this.store.dispatch(accountActions.logout());
    }

    requestResetPassword(email: string): void {
        this.store.dispatch(accountActions.requestResetPassword({ email }));
    }

    resetPassword(password: string, token: string): void {
        this.store.dispatch(accountActions.resetPassword({ password, token }));
    }

    activate(token: string): void {
        this.store.dispatch(accountActions.activate({ token }));
    }

    clear(): void {
        this.store.dispatch(accountActions.clear());
    }
}
