import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@app/shared/models';
import { map, Observable } from 'rxjs';
import { LoginModel, RegisterModel } from '../models';

@Injectable({
    providedIn: 'root',
})
export class HttpService {
    constructor(private httpClient: HttpClient) {}

    register(value: RegisterModel): Observable<unknown> {
        const params = {
            email: value.email.trim().toLocaleLowerCase(),
            password: value.password,
            consent: value.consent,
        };
        const headersParams = value.assignBookingId ? { headers: { 'X-Gtv-Order-Assign': value.assignBookingId } } : {};

        return this.httpClient
            .addServer()
            .post<ApiResponse<unknown>>(`api/auth/register`, params, headersParams)
            .pipe(
                map((response) => {
                    if (response.success === false) {
                        throw response.data;
                    }
                    return response;
                }),
                map((response) => response.data),
            );
    }

    login(value: LoginModel): Observable<unknown> {
        const params = {
            email: value.email.trim().toLocaleLowerCase(),
            password: value.password,
        };

        if (value.assignBookingId) {
            return this.httpClient
                .addServer()
                .skipRefreshToken()
                .post<ApiResponse<unknown>>(`api/auth/login`, params, {
                    headers: {
                        'X-Gtv-Order-Assign': value.assignBookingId,
                    },
                });
        }
        return this.httpClient.addServer().skipRefreshToken().post<ApiResponse<unknown>>(`api/auth/login`, params);
    }

    logout(): Observable<unknown> {
        return this.httpClient.addServer().post<ApiResponse<unknown>>(`api/user/logout`, {});
    }

    requestResetPassword(email: string): Observable<unknown> {
        const params = { email: email.trim().toLocaleLowerCase() };
        return this.httpClient.addServer().skipRefreshToken().skipErrorHandler().post<ApiResponse<unknown>>(`api/auth/password-reset`, params);
    }

    resetPassword(newPassword: string, token: string): Observable<unknown> {
        const params = { newPassword };
        return this.httpClient
            .addServer()
            .skipRefreshToken()
            .skipErrorHandler()
            .post<ApiResponse<unknown>>(`api/auth/token-password-change/${token}`, params)
            .pipe(
                map((response) => {
                    if (response.success === false) {
                        throw response;
                    }
                    return response;
                }),
                map((response) => response),
            );
    }

    activate(token: string): Observable<unknown> {
        return this.httpClient.addServer().skipRefreshToken().skipErrorHandler().post<ApiResponse<unknown>>(`api/auth/activate/${token}`, {});
    }

    resendActivationLink(email: string): Observable<unknown> {
        return this.httpClient.addServer().skipRefreshToken().post<ApiResponse<unknown>>(`api/auth/reactivate/${email.trim().toLocaleLowerCase()}`, {});
    }
}
