import { HttpErrorResponse } from '@angular/common/http';
import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { ErrorsModel, LoginModel, RegisterModel } from '../models';

export const accountActions = createActionGroup({
    source: 'Account',
    events: {
        Register: props<{ value: RegisterModel }>(),
        'Register Success': emptyProps(),
        'Register Error': props<{ errors: ErrorsModel }>(),
        Login: props<{ value: LoginModel }>(),
        'Login Success': emptyProps(),
        'Login Error': props<{ response: HttpErrorResponse; email: string }>(),
        Logout: emptyProps(),
        'Logout Success': emptyProps(),
        'Logout Error': emptyProps(),
        'Request Reset Password': props<{ email: string }>(),
        'Request Reset Password Success': emptyProps(),
        'Request Reset Password Error': props<{ errors: ErrorsModel }>(),
        'Reset Password': props<{ password: string; token: string }>(),
        'Reset Password Success': emptyProps(),
        'Reset Password Error': props<{ response: HttpErrorResponse | { data: ErrorsModel } }>(),
        Activate: props<{ token: string }>(),
        'Activate Success': emptyProps(),
        'Activate Error': props<{ errors: ErrorsModel }>(),
        'Resend Activation Link': props<{ email: string }>(),
        'Resend Activation Link Success': emptyProps(),
        'Resend Activation Link Error': emptyProps(),
        Clear: emptyProps(),
    },
});
