import { inject } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';
import { AccountFacade } from '../facades/account.facade';

export function accountCleanup<T>(): CanDeactivateFn<T> {
    return () => {
        const accountFacade = inject(AccountFacade);
        accountFacade.clear();

        return true;
    };
}
